import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CanActivate, Router } from '@angular/router';
import { LeadService } from '../../lead/lead.service';
@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  constructor(private $lead: LeadService, private router: Router) {}

  canActivate(): Observable<boolean> | boolean {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      return this.$lead.currentLead().pipe(
        map((res: any) => {
          if (res?.data?.currentLead?.id) {
            this.router.navigate(['/internal']);
            return false;
          } else {
            return true;
          }
        })
      );
    } else {
      return true;
    }
  }
}
